import './App.css';
import React from 'react';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';

function App() {
  return (
    <>
      <Button variant="contained">Hello prem</Button>
      <Button variant="outlined" color="error">
        Error
      </Button>
      <Close />
    </>
  );
}

export default App;
